import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import useSiteMetadata from '../useSiteMetadata'
import { fixedImage } from '../propTypes'

const Seo = ({ title, description, lang, shareImage }) => {
  const { metaTitle, metaDescription } = useSiteMetadata(title, description)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: [process.env.SITE_BASE_URL, shareImage.src].join('')
        },
        {
          property: `twitter:image`,
          content: [process.env.SITE_BASE_URL, shareImage.src].join('')
        },
      ]}
    >
      <link rel="preconnect" href="https://google-analytics.com/" crossorigin />
      <link rel="preconnect" href="https://maps.googleapis.com" crossorigin />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  description: ``,
}

Seo.propTypes = {
  shareImage: fixedImage,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
