import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box, Heading } from 'rebass'
import Img from 'gatsby-image'
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from './LanguageContext'
import Link from './Link'
import Container from './Container'
import ImgOverlay from './ImgOverlay'
import LanguagePicker from './LanguagePicker'
import useSiteMetadata from '../useSiteMetadata'

const FullImg = styled(Img)`
  height: 100%;
  max-height: ${props => props.variant === 'large' ? 750 : 200}px;
`

const SlideshowContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  max-height: ${props => props.variant === 'large' ? 750 : 200}px;

  .react-slideshow-container {
    max-height: ${props => props.variant === 'large' ? 750 : 200}px;
  }
`

const fadeProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  autoplay: true,
  indicators: false,
}

const PageHeader = ({ title, heroTitle, paths, variant = 'large' }) => {
  const { metaTitle } = useSiteMetadata(title)
  const { lang: currentLanguage, isDefaultLanguage } = React.useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    {
      logoImage: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }

      slideshowImages: allFile(
        filter: {
          relativePath: { regex: "/images/slideshow/" }
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        }
        sort: {
          fields: name
        }
      ) {
        totalCount
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 900, quality: 30, srcSetBreakpoints:[ 200, 400, 600, 800, 1000 ]) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box
      sx={{
        mx: 'auto',
        maxWidth: 1350,
      }}
    >
      <Box py={2} px={3}>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to={`/${isDefaultLanguage ? '' : currentLanguage}`}>
            <Img
              alt={metaTitle}
              fixed={data.logoImage.childImageSharp.fixed}
            />
          </Link>
          <LanguagePicker paths={paths} />
        </Flex>
      </Box>

      <Box sx={{ position: 'relative' }}>
        <SlideshowContainer
          variant={variant}
        >
          <Fade {...fadeProperties}>
            {
              data.slideshowImages.edges.map((item, i) => (
                <FullImg
                  key={i}
                  variant={variant}
                  fluid={item.node.childImageSharp.fluid}
                />
              ))
            }
          </Fade>

          <ImgOverlay>
            <Container>
              <Heading
                as="h1"
                fontSize={[5, 7, 8]}
                color="heading.light"
                textAlign="center"
                sx={{
                  zIndex: 3,
                  position: 'relative',
                  textShadow: '2px 2px 10px rgba(0,0,0,.9)'
                }}
              >
                {heroTitle}
              </Heading>
            </Container>
          </ImgOverlay>
        </SlideshowContainer>

        <FullImg
          variant={variant}
          fluid={data.slideshowImages.edges[0].node.childImageSharp.fluid}
        />
      </Box>
    </Box >
  )
}

export default PageHeader
