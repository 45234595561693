import React from 'react'
import { Flex } from 'rebass'
import { useStaticQuery, graphql } from 'gatsby'
import List from './List'
import ListItem from './ListItem'
import TextLink, { ExternalLink } from './TextLink'
import { LanguageContext } from './LanguageContext'

const Navigation = () => {
  const { translations: t, lang } = React.useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    {
      pages: allSitePage(filter: {
        path: {ne: "/"},
        isCreatedByStatefulCreatePages: {eq: false},
      }) {
        edges {
          node {
            id
            path
            context {
              titleKey
              language
            }
          }
        }
      }
    }
  `)

  const pages = data.pages.edges
  const currentPages = pages.filter((page) => {
    return page.node.context.language === lang && !!page.node.context.titleKey
  })

  return (
    <Flex
      as="nav"
      width={1}
      role="navigation"
    >
      <List>
        {
          currentPages.map(({ node: page }) => (
            <ListItem
              pb={2}
              key={page.id}
            >
              <TextLink
                as="a"
                partiallyActive
                to={page.path}
              >
                {t.meta.pageTitle[page.context.titleKey]}
              </TextLink>
            </ListItem>
          ))
        }
        <ListItem pb={2}>
          <ExternalLink
            as="a"
            href="https://pusztahaz.hu"
          >
            {t.otherHouseLink}
          </ExternalLink>
        </ListItem>
      </List>
    </Flex>
  )
}

export default Navigation
